.appLayout {
    margin: auto;
    width: calc(100% - 30px);

    @media (max-width: 1100px) and (min-width: 831px) {
        width: 800px;
    }

    @media (max-width: 1300px) and (min-width: 1101px) {
        width: 1000px;
    }

    @media (min-width: 1301px) {
        width: 1200px;
    }
}

#thepage {
    position: absolute;
    display: inline-block;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: 851px) {
    #thepage {
        width: 80%;
    }
}
@media (max-width: 850px) {
    #thepage {
        width: 90%;
    }
}