html {
    font-size: 100px;
}

body {
    background-color: #0082c8;
    color: #fff;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    font-size: .14rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    word-wrap: break-word;
}
a:not(.nocolor) {
    text-decoration: none;
    color: #00F;
    cursor: pointer;
}
a:not(.nocolor):hover {
    /*text-decoration: underline;*/
    border-bottom: 1px solid #00F;
}
a.nocolor {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.noScrollBar::-webkit-scrollbar {
    display: none;
}
