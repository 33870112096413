.home {
    width: 100%;

    #navBar {
        width: 100%;
        height: 40px;
        position: absolute;
        top: 0px;
        left: 0px;

        #path {
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 8px;
            font-family: sans-serif;
            font-size: .18rem;

            span {
                margin: 5px;
            }

            span.pathFolderSeparator {
                cursor: default;
            }

            span.pathFolder {
                cursor: pointer;
            }

            span.pathFolder:hover {
                border-bottom: 1px solid #fff;
            }

            span.pathFolder.activeFolder {
                border-bottom: 1px solid #fff;
            }
        }

        #loginButton, #loginName {
            display: inline-block;
            position: absolute;
            top: 8px;
            right: 8px;
            font-family: sans-serif;
            font-size: .2rem;
            cursor: pointer;
        }

        #loginButton:hover {
            text-decoration: underline;
        }

        #loginName {
            right: 90px;
        }
    }

    #menuBar {
        width: 100%;
        height: 40px;
        position: absolute;
        left: 0px;
        top: 40px;
    }

    #viewEditSwitch {
        display: inline-block;
        width: 200px;
        height: 30px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-family: sans-serif;
        font-size: .2rem;

        span {
            display: inline-block;
            position: absolute;
            top: 4px;
            transform: translateX(-50%);
            cursor: pointer;
        }

        #switchSlider {
            width: 50%;
            height: 100%;
            background-color: rgba(255,255,255,0.2);
            border-radius: 15px;
            transition: transform .5s;
            cursor: pointer;

            &.right {
                transform: translateX(100%);
            }
        }
    }

    #viewerContainer {
        margin-top: 80px;
        display: inline-block;
        overflow: auto;
    }
    #editor, #viewer {
        width: 100%;
        /*height: 1000px;*/
        /*overflow: hidden;*/
        word-wrap: break-word;
        display: inline-block;
        font-family: 'Inconsolata', monospace;
        font-size: .18rem;
        line-height: .24rem;
        color: #fff;
        border: none;
        background: none;
        resize: none;
        outline: none;
        padding: 0px;
    }
    #editor {
        margin-top: 80px;
    }

    #editor, #viewerContainer {
        height: calc(100vh - 100px);
    }

    .hidden {
        display: none !important;
    }

    .markdown {
        /*font-size: .2rem;*/
        font-size: .18rem;
        font-family: 'Avenir', sans-serif;

        h1.title {
            margin: .1rem 0rem;
            font-size: .5rem;
            font-family: 'LatoLight';
            width: calc(100% - 25px);
            display: inline-block;
        }

        b {
            font-weight: bold;
        }
        i {
            font-style: italic;
        }
        s {
            text-decoration: line-through;
        }
        mark {
            background-color: yellow;
            color: black;
        }
        ins {
            text-decoration: underline;
        }
        sup {
            vertical-align: super;
            font-size: smaller;
        }
        sub {
            vertical-align: sub;
            font-size: smaller;
        }
        p > code {
            font-family: monospace;
            font-size: .18rem;
            border: 1px solid #EEEEB9;
            background-color: #FCFCE1;
            border-radius: 5px;
        }
        pre {
            font-family: monospace;
            border: 1px solid #EEEEB9;
            background-color: #FCFCE1;
            border-radius: 5px;
            margin: 0rem;
            overflow-x: auto;
        }
        pre code {
            font-family: monospace;
            font-size: .16rem;
        }
        p {
            width: 100%;
            display: inline-block;
            /*margin: .1rem 0rem;*/ /* This goes with the change to look for \n instead of \n\n */
            margin: .1rem 0rem;
        }
        table {
            /*border-collapse: collapse;*/
            margin: .05rem 0rem;
            font-size: .18rem;
            white-space: pre-wrap;
        }
        table th, table td {
            /*border: 2px solid rgba(0, 0, 0, 0);/* #aaa; */
            padding: 2px 5px;
        }
        table tr.lightRow {
            background-color: rgba(255, 255, 255, 0.1);
        }
        table th{
            font-weight: bold;
        }
        table td._L{
            text-align: left;
        }
        table td._C{
            text-align: center;
        }
        table td._R{
            text-align: right;
        }
        blockquote {
            border-left: 4px solid rgba(0, 0, 0, .2); /* 4px solid #ccc */
            padding: 2px 15px; /* 0 15px */
            color: #fff; /* #666 */
            margin: .05rem 0rem;
            background-color: rgba(0, 0, 0, .1); /* #f0f0f0 */
        }
        blockquote blockquote {
            display: inline-block;
            border-top: 2px solid rgba(0, 0, 0, .2); /* 1px solid #ddd */
            /* border-bottom: 1px solid #ddd;*/
        }
        hr {
            margin: .05rem 0rem;
            border: 0;
            color: transparent;
            background: #bbb;
            height: 2px;
            padding: 0;
        }
        h1:not(.title), h2, h3, h4, h5, h6 {
            margin: .08rem 0rem;
        }
        h1:not(.title) {
            font-size: .4rem;
        }
        h2 {
            font-size: .35rem;
        }
        h3 {
            font-size: .3rem;
        }
        h4 {
            font-size: .25rem;
        }
        h5 {
            font-size: .2rem;
        }
        h6 {
            font-size: .15rem;
        }
        ul {
            -webkit-padding-start: 25px;
            margin: 0px;
        }
    }
}